import {ExportOutlined} from '@ant-design/icons';
import {Comment, Divider, Tooltip} from 'antd';
import React from 'react';

const ChatMessage = React.memo(({message}) => {
  return (
    <Comment
      style={{
        alignContent: message.author === 'You' ? 'end' : 'start',
        alignSelf: message.author === 'You' ? 'flex-end' : 'flex-start',
        backgroundColor: message.author === 'You' ? '#ffdd00' : '#f0f0f0',
        border: '1px solid #f0f0f0',
        borderRadius: '5px',
        margin: '0 20px 0 0',
        padding: '0 10px',
        width: 'fit-content',
      }}
      author={message.author}
      content={
        message.preamble ? (
          <div>
            <p>{message.preamble}</p>
            <br />
            {message.artifacts.map((artifact) => (
              <p key={artifact.id + artifact.footnoteNumber}>
                <Tooltip
                  overlayStyle={{
                    width: 'fit-content',
                    maxWidth: '500px',
                    maxHeight: '500px',
                    overflowY: 'auto',
                  }}
                  placement="right"
                  // display the source text when hovering over the icon, including the original line breaks
                  title={
                    <pre
                      style={{
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                      }}
                    >
                      {artifact.content}
                    </pre>
                  }
                  // title={artifact.content}
                >
                  {artifact.footnoteNumber} {artifact.name}{' '}
                  <a
                    key={artifact.id}
                    href={artifact.source}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ExportOutlined />
                  </a>
                </Tooltip>
              </p>
            ))}
            <Divider />
            <p>{message.content}</p>
          </div>
        ) : (
          <p>{message.content}</p>
        )
      }
      datetime={message.time}
      key={message.content}
    />
  );
});

export default ChatMessage;
