import {
  CalendarOutlined,
  NumberOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {Button, Input, Table, Tooltip} from 'antd';
import {OperatorFilter} from 'components/genericComponents/FilterFields';
import FiltersDisplay from 'components/genericComponents/FiltersDisplay';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import Highlighter from 'react-highlight-words';
import {Link} from 'react-router-dom';

const DataTable = ({params, setParams, data, meta, bordered, size}) => {
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({});
  const searchInput = useRef();

  useEffect(() => {
    if (!meta) return;
    // set filteredInfo to object with all keys from meta.columns, with values from params
    const newFilteredInfo = {};
    meta?.columns?.forEach((col) => {
      if (params.has(`filter_${col.alias}`)) {
        newFilteredInfo[col.alias] = params.get(`filter_${col.alias}`);
      } else {
        newFilteredInfo[col.alias] = '';
      }
    });
    setFilteredInfo(newFilteredInfo);
  }, [meta, params]);

  useEffect(() => {
    const sort = params.get('sort');
    if (sort) {
      const [columnKey, order] = sort.split(':');
      setSortedInfo({columnKey, order});
    }
  }, [params]);

  useEffect(() => {
    setPagination({
      pageSize: params.get('page_size') || 50,
      current: params.get('page') || 1,
      total: data?.total,
    });
  }, [params, data?.total]);

  const setupTableData = useCallback(() => {
    return (
      data?.report?.map((row, index) => ({
        ...row,
        key: index,
        ...Object.fromEntries(
          Object.entries(row).map(([k, v]) => [k, v ?? ''])
        ),
      })) || []
    );
  }, [data]);

  const setColumnSorter = useCallback((col) => {
    return (a, b) => {
      if (!a[col.column_name] || !b[col.column_name]) return;
      if (col.column_type === 'INTEGER')
        return parseInt(a[col.column_name]) - parseInt(b[col.column_name]);
      if (col.column_type === 'STRING')
        return a[col.column_name].toString().localeCompare(b[col.column_name]);
      if (col.column_type === 'DATE')
        return new Date(a[col.column_name]) - new Date(b[col.column_name]);
    };
  }, []);

  const setColumnRender = useCallback(
    (col) => {
      if (col.is_link) {
        return (url, record) => {
          if (!url) return null;
          const linkText = col.link_text ? record[col.link_text] : url;
          if (url.includes('http')) {
            return (
              <a href={url} target="_blank" rel="noreferrer">
                {linkText ?? url}
              </a>
            );
          } else {
            return (
              <Link to={url} target="_blank">
                {linkText ?? url}
              </Link>
            );
          }
        };
      }
      if (['STRING', 'INTEGER'].includes(col.column_type)) {
        return (text) => {
          const colFilteredInfo = Array.isArray(filteredInfo[col.alias])
            ? filteredInfo[col.alias]
            : [filteredInfo[col.alias]];
          return (
            <div>
              {colFilteredInfo.length ? (
                <Highlighter
                  highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                  searchWords={colFilteredInfo}
                  autoEscape
                  textToHighlight={text?.toString() || ''}
                />
              ) : (
                text
              )}
            </div>
          );
        };
      }
      return null;
    },
    [filteredInfo]
  );

  const getColumnSearchProps = useCallback(
    (col) => {
      const dataIndex = col.column_name;
      if (col.filter_type === 'FREE_TEXT') {
        return {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{padding: 8}}>
              <Input
                ref={searchInput}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => handleSearch(selectedKeys, confirm)}
                style={{width: 188, marginBottom: 8, display: 'block'}}
              />
              <div className="flex-row">
                <Button
                  onClick={() => handleReset(clearFilters, confirm)}
                  size="small"
                  style={{width: 90}}
                >
                  Reset
                </Button>
                <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{width: 90}}
                >
                  Search
                </Button>
              </div>
            </div>
          ),
          filterIcon: <SearchOutlined />,
          onFilterDropdownVisibleChange: (visible) =>
            visible && setTimeout(() => searchInput.current.select()),
        };
      }
      if (['INTEGER', 'DATE'].includes(col.filter_type)) {
        return {
          filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => (
            <OperatorFilter
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
              handleSearch={handleSearch}
              confirm={confirm}
              col={col}
            />
          ),
          filterIcon:
            col.filter_type === 'DATE' ? (
              <CalendarOutlined />
            ) : (
              <NumberOutlined />
            ),
        };
      }
      if (col.filter_type === 'OPTIONS') {
        return {
          filters: col.options.map((filter) => ({text: filter, value: filter})),
          onFilter: (value, record) =>
            Array.isArray(record[dataIndex])
              ? record[dataIndex].includes(value)
              : record[dataIndex] === value,
        };
      }
      return null;
    },
    [filteredInfo]
  );
  const getFilterValueForCol = useCallback(
    (col) => {
      if (col.filter_type === 'OPTIONS') {
        return Array.isArray(filteredInfo[col.alias])
          ? filteredInfo[col.alias]
          : filteredInfo[col.alias]
            ? filteredInfo[col.alias].split(',')
            : [];
      } else {
        return filteredInfo[col.alias] || '';
      }
    },
    [filteredInfo]
  );

  const getColumnsForTable = useCallback(() => {
    if (!meta?.columns) return [];
    const cols = meta.columns.map((col) => ({
      title: col.description ? (
        <Tooltip title={col.description}>{col.column_name}</Tooltip>
      ) : (
        col.column_name
      ),
      dataIndex: col.column_name,
      key: col.column_name,
      fixed: col.freeze ? 'left' : null,
      sorter: setColumnSorter(col),
      sortOrder:
        sortedInfo.columnKey === col.column_name ? sortedInfo.order : null,
      width: Math.max(col.column_name.length * 10 + 25, 125),
      filteredValue: getFilterValueForCol(col),
      render: setColumnRender(col),
      ...getColumnSearchProps(col),
    }));
    // pull out the columns that are not frozen
    const nonFrozenCols = cols.filter((col) => !col.fixed);
    // pull out the columns that are frozen
    const frozenCols = cols.filter((col) => col.fixed);
    // return the frozen columns first, then the non-frozen columns
    return [...frozenCols, ...nonFrozenCols];
  }, [meta?.columns, filteredInfo, sortedInfo]);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const newFilters = {...filteredInfo, ...filters};
    const newSorter = {...sortedInfo, ...sorter};
    const newPagination = {
      ...pagination,
      current: extra.action === 'paginate' ? pagination.current : 1,
    };

    setFilteredInfo(newFilters);
    setSortedInfo(newSorter);
    setPagination(newPagination);

    const newParams = new URLSearchParams();
    newParams.append('page', newPagination.current);
    newParams.append('page_size', newPagination.pageSize);
    if (newSorter.columnKey && newSorter.order)
      newParams.append('sort', `${newSorter.columnKey}:${newSorter.order}`);
    Object.entries(newFilters).forEach(
      ([key, value]) => value && newParams.append(`filter_${key}`, value)
    );
    setParams(newParams.toString());
  };

  const handleSearch = (selectedKeys, confirm) => confirm();
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };
  const handleClearFilters = () => setParams('?page=1&page_size=50');
  const handleFilterChange = (alias, value) =>
    handleTableChange(
      pagination,
      {...filteredInfo, [alias]: value},
      sortedInfo,
      {action: 'filter'}
    );

  const getFilterValues = () =>
    meta?.filters?.map((filter) => ({
      ...filter,
      value: filteredInfo[filter.alias],
    })) || [];

  return (
    <>
      <FiltersDisplay
        filters={getFilterValues()}
        handleFiltersChange={handleFilterChange}
        handleClearFilters={handleClearFilters}
        key={`FiltersDisplay_${params}${JSON.stringify(meta?.filters)}`}
      />
      <Table
        pagination={pagination}
        dataSource={setupTableData()}
        columns={getColumnsForTable()}
        key={`Table_${params}`}
        size={size || 'small'}
        bordered={bordered || true}
        onChange={handleTableChange}
        showSorterTooltip={false}
        scroll={{x: '100%', y: '80vh'}}
        rowKey="key"
      />
    </>
  );
};

export default DataTable;
